h1 {
    font-size: 2.5rem; /* 40px */
    font-weight: 700;
}

h2 {
    font-size: 2rem; /* 32px */
    font-weight: 600;
}

h3 {
    font-size: 1.75rem; /* 28px */
    font-weight: 600;
}

h4 {
    font-size: 1.5rem; /* 24px */
    font-weight: 500;
}

h5 {
    font-size: 1.25rem; /* 20px */
    font-weight: 500;
}

h6 {
    font-size: 1rem; /* 16px */
    font-weight: 500;
}
.custom-dash-border {
    border-style: dashed;
    border-width: 2px; /* Adjust thickness as needed */
    border-color: "grey-10";
    border-image: repeating-linear-gradient(to right, grey 0, grey 10px, transparent 10px, transparent 20px) 100% / 1px 1px;
  }
  